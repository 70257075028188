import React, { Fragment, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { rejectToReview } from "../../actions/research";

const RejectToReviewr = ({ id, user, rejectToReview }) => {
  // for modals
  const [addModals, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const onSubmit = () => {
    rejectToReview(id, user._id);
  };

  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <Dropdown.Item onClick={handleShow}> عدم قبول تقييم البحث</Dropdown.Item>

      <Modal className="text-right" show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> تآكيد طلب عدم قبول تقييم الورقة </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <h3>هل انت متآكد من رفضك تقييم هده الورقة؟</h3>
          </div>
          <Modal.Footer>
            <button
              className="btn-custom btn-dark center"
              onClick={handleClose}
            >
              الغاء
            </button>
            <button className="btn btn-danger center" onClick={onSubmit}>
              نعم
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  rejectToReview,
})(RejectToReviewr);
