import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faInfoCircle,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";

import Details from "../../components/modals/Details";
import ChangeStatus from "../../components/modals/changeStatus";
import AddReviewer from "../../components/modals/Reviewer";
import { accpA, rejeA, accpP, rejeP, ChangeS } from "./Language";
import AddPaperAfetrEdit from "../modals/addPaperAfetrEdit";
import RejectToReview from "../modals/RejectToReview";
import AccebtableStatus from "../modals/AccebtableStatus";

// import EditProductModal from "../models/Edit/EditProductModal";

const ResearchItem = ({ user, research, lang, i, rolls, reviewer_id }) => {
  const {
    _id,
    researchName,
    theHub,
    author,
    country,
    paper,
    status,
    status2,
    Reviewer,
    Reviewer2,
    status3,
    finalResualt,
    paperAfterEdit,
    TypeOfParticipation,
  } = research;

  return (
    <tr>
      <td className="center-align">{i}</td>
      <td>
        { status3 !== "rejected"  &&
        <>
 {paperAfterEdit && paperAfterEdit !== "" && (
          <a href={paperAfterEdit} style={{ fontSize: "1.5rem" }}>
            <FontAwesomeIcon icon={faDownload} />
          </a>
        )}{" "}
        {(rolls === 1 || rolls === 2) && (
          <a href={paper} style={{ fontSize: "1.5rem", color: "red" }}>
            <FontAwesomeIcon icon={faDownload} />
          </a>
        )}{" "}
        </>
        }
       
      </td>
      <td className="center-align">{researchName}</td>
      <td className="center-align">
        {rolls === 1 || rolls === 2 ? author : "الاسم مشفر "}
      </td>
      <td className="center-align">{TypeOfParticipation}</td>

      <td className="center-align">{theHub}</td>
      <td className="center-align">
      {rolls === 1 || rolls === 2 ? country : "مشفر "}
        </td>

      <td className="center-align">
        {Reviewer && (user._id == Reviewer.id || rolls === 1 || rolls === 2) && 
        <>
        <p> م1. {Reviewer.name}</p>
        {status && status !== "" ? (
          <p
            className={
              status > 50
                ? "max-content text-success"
                : "max-content text-danger"
            }
          >
            {" "}
            <p> نتيجة المقيم الاول : {status}</p>
          </p>
        ) : (
          <p>لم يتم مراجعة الورقة</p>
        )}
</>
}
        {Reviewer2 && (user._id == Reviewer2.id || rolls === 1 || rolls === 2) &&
        <>
         <p> م2. {Reviewer2.name}</p>
        {status2 && status2 !== "" ? (
          <p
            className={
              status2 >50
                ? "max-content text-success"
                : "max-content text-danger"
            }
          >
            {" "}
            <p> نتيجة المقيم الثاني : {status2}</p>
          </p>
        ) : (
          <p className="max-content">لم يتم مراجعة الورقة</p>
        )}
        </>
}
        {rolls === 1 || rolls === 2  && finalResualt && finalResualt > 0 &&  <p
            className={
              finalResualt >100
                ? "max-content text-success"
                : "max-content text-danger"
            }
          >المجموع :  {finalResualt}</p>}


{status3 && status3 === "rejected"  && <p className="max-content bg-danger p-1" >الورقة مرفوضة</p>}
{status3 && status3 === "accepted"  && <p className="max-content bg-success p-1" >الورقة مقبولة</p>}
{status3 && status3 === "acceptedwithnotes"  && <p className="max-content bg-primary p-1" >الورقة تحت التعديل</p>}
      </td>

      <td className="center-align">
        <Dropdown>
          <Dropdown.Toggle variant="Info" id="dropdown-basic">
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* تفاصيل الورقة */}
            <Details research={research} lang={lang} rolls={rolls} />
            {/* تقييم البحث و ابداء النتيجة */}

            {(Reviewer &&
              user._id == Reviewer.id &&
              (!status || status === "")) &&<ChangeStatus research={research} />}
            
              {(Reviewer2 &&
              user._id == Reviewer2.id &&
              (!status2 || status2 === "")) ? (
              <ChangeStatus research={research} />
            ) : null}
            {(rolls === 1 || rolls === 2) && (
              <>
                {paperAfterEdit && paperAfterEdit !== "" && (
                  <AddReviewer research={research} />
                )}
                <AddPaperAfetrEdit id={_id} reviewer_id={reviewer_id} />
                {status3 && status3 === "accepted" || status3 === "rejected" ? null : <AccebtableStatus id={_id} /> }
              </>
            )}
            {(rolls === 0 &&
              Reviewer &&
              user._id == Reviewer.id &&
              (!status || status === "")) ||
            (Reviewer2 &&
              user._id == Reviewer2.id &&
              (!status2 || status2 === "")) ? (
              <RejectToReview id={_id} />
            ) : null}

           
            
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

ResearchItem.propTypes = {
  research: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ResearchItem);
