import React, { Fragment, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import Alert from "../Layout/Alert";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ChangeState } from "../../actions/research";

const AcceptableStatus = ({ id ,setAlert ,ChangeState}) => {
  // for modals
  const [addModals, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [Status, setStatus] = useState({
    acceptedNotes :"",
    states:"" ,
    _id:id,
  });

  const {acceptedNotes ,states ,_id} = Status
  const onChange = (e) => {
    setStatus({ ...Status, [e.target.name]: e.target.value });    
  };
  const onSubmit = () => {
    if(states === ""){
      setAlert(
        "لم يتم تحديد الحالة",
        "danger"
      );
    }else{
      ChangeState(acceptedNotes ,states ,_id)
      setAlert(
        "تم ارسال التغيير بنجاح",
        "success"
      );
      setTimeout(handleClose, 3000);
    }
  };

  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <Dropdown.Item onClick={handleShow}> تغيير حالة البحث</Dropdown.Item>

      <Modal className="text-right" show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> تغيير حالة البحث </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <h3>اختر الحالة </h3>
            <select name="states" onChange={(e) => onChange(e)}>
               <option value=""> اختر الحالة المراد ارسالها</option>
               <option value="accepted"> قبول الورقة</option>
               <option value="acceptedwithnotes"> قبول مع الملاحظات و التغيير</option>
               <option value="rejected"> رفض</option> 
            </select>
            <br />
            <h3>اضافة ملاحظات ان وجدت</h3>
            <CKEditor
               editor={ClassicEditor}
               data={acceptedNotes}
               onChange={(event, editor) => {
               const data = editor.getData();
               setStatus({ ...Status, acceptedNotes: data });
          }}
        />

        <br />
          <Alert />
          </div>
          <Modal.Footer>
            <button
              className="btn-custom btn-dark center"
              onClick={handleClose}
            >
              الغاء
            </button>
            <button className="btn btn-danger center" onClick={onSubmit}>
              نعم
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};


export default connect(null, {
  ChangeState,
  setAlert,
})(AcceptableStatus);
