import axios from "axios";
import {
  GET_RESEARCHS,
  GET_RESEARCH,
  RESEARCH_ERROR,
  ADD_RESEARCH,
  UPDATE_RESEARCH,
  FILTER_RESEARCH,
  FILTERING_RESEARCH,
  CLEAR_FILTER,
  GET_REVIEWER,
  USER_AGREE,
  CHANGE_LANG,
  USER_UPDATE,
  USER_LOADED,
  USER_DELETE
} from "./types";

// Get Researchs
export const getResearchs = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/researchs");

    dispatch({
      type: GET_RESEARCHS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Get one Research
export const getResearch = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RESEARCH,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// filter Category
export const filterResearch = (text) => (dispatch) => {
  dispatch({ type: FILTER_RESEARCH, payload: text });
};

// filter Category
export const filtering = (filter) => (dispatch) => {
  dispatch({ type: FILTERING_RESEARCH, payload: filter });
};

//clear filter
export const clearFilter = () => (dispatch) => {
  dispatch({ type: CLEAR_FILTER });
};

// Add Category
export const addAbstruct = (
  CV,
  researchName,
  abstruct,
  theHub,
  author,
  email,
  phone,
  country,
  Organization,
  Qualification,
  Degree,
  author1,
  email1,
  author2,
  email2
) => async (dispatch) => {
  const formData = new FormData();
  formData.append("CV", CV);
  formData.append("researchName", researchName);
  formData.append("abstruct", abstruct);
  formData.append("theHub", theHub);
  formData.append("author", author);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("country", country);
  formData.append("Organization", Organization);
  formData.append("Qualification", Qualification);
  formData.append("Degree", Degree);
  formData.append("author1", author1);
  formData.append("email1", email1);
  formData.append("author2", author2);
  formData.append("email2", email2);

  try {
    const res = await axios.post("/api/researchs", formData);
    dispatch({
      type: ADD_RESEARCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// تغيير الحالة
export const changeStatus = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(
      `/api/researchs/${formData.research_id}/status`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_RESEARCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Get User
export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/researchs/reviewers");

    dispatch({
      type: GET_REVIEWER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// إضافة مسؤول
export const addReviewer = (reviewer, _id) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(
      `/api/researchs/${_id}/reviewer`,
      reviewer,
      config
    );

    dispatch({
      type: UPDATE_RESEARCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const ChangeLang = (lang) => async (dispatch) => {
  dispatch({
    type: CHANGE_LANG,
    payload: lang,
  });
};

// Add Paper After Edit
export const addPaper = (paper, id) => async (dispatch) => {
  const formData = new FormData();
  formData.append("Paper", paper);

  try {
    const res = await axios.put(
      `/api/researchs/addpaperafteredit/${id}`,
      formData
    );
    dispatch({
      type: UPDATE_RESEARCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const rejectToReview = (id, reviewer_id) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/researchs/${id}/rejectoreview/${reviewer_id}`
    );
    dispatch({
      type: UPDATE_RESEARCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const IMAgree = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/researchs/${id}/imagree`);
    dispatch({
      type: USER_UPDATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const addFileToReviewer = (file , id) =>async dispatch => {
  const formData = new FormData();
  for (let i = 0; i < file.length; i++) {
    formData.append("File", file[i]);
  }
  try {
    const res = await axios.put(`/api/researchs/fileto/${id}`,formData)
    dispatch({
      type : USER_UPDATE,
      payload:res.data
    })
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
}

// delete Account
export const deleteAccount = (_id)=> async dispatch =>{
 
  let res
  try {
    res = await axios.delete(`/api/researchs/deleteaccount/${_id}`)
    dispatch({
      type : USER_DELETE,
      payload:_id
    })
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
    
  }
}

// Change state 
export const ChangeState =(acceptedNotes ,states ,_id) => async dispatch =>{
  
  let res ; 
  const config = { header: { "Content-Type": "application/json" } };
  try {
    res = await axios.post(`/api/researchs/acceptable/${_id}` , {acceptedNotes ,states} ,config)
    dispatch({
      type: UPDATE_RESEARCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RESEARCH_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
}
