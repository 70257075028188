import ReviwersItem from './ReviwersItem';
import React from 'react';
import {connect} from "react-redux";

const ReviewersPage = ({reviewers}) => {
    return (
       <>
       <div className="row">
       <div className="col s12 container">
       <h1 className="text-center">المراجعين</h1>
                    <table className="striped center-align">
                      <thead>
                        <tr>
                          <th className="center-align">ر.م</th>
                          <th className="center-align">الملف </th>
                          <th className="center-align">الاسم </th>
                          <th className="center-align">البريد الالكتروني</th>
                          <th className="center-align">عدد البحوث</th>
                          <th className="center-align">المحاور</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                          { reviewers.map((reviewer , i) =>
                                  <ReviwersItem reviewer={reviewer} i={i+1} key={reviewer._id} />
                              )}

                      </tbody>
                    </table>
                    </div>
                    </div>
       </>
    )
}
const mapStateToProps = state =>({
    reviewers : state.researchs.reviewers,
})

export default connect(mapStateToProps)(ReviewersPage)
