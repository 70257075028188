import React, { Fragment, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { addFileToReviewer } from "../../actions/research";
import Alert from "../Layout/Alert";
import FormInput from "../form-input/form-input.component";

const AddfiletoReviewer = ({ lang, setAlert, addFileToReviewer, id }) => {
  // for modals
  const [addModals, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  // for regester

  const [file, setFile] = useState("");
  const [filename, setFilename] = useState(
    " تحميل المستنذ  "
  );

  const onChangefile = (e) => {
    if 
    (e.target.files[0]!==undefined &&
      (e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type === "image/jpeg")
    ) {
      setFile(e.target.files);
      setFilename(e.target.files[0].filename);
    } else {
      setAlert(
        "الملف غير مدعوم ارجو التأكد من ان يكون الملف بصيغة jpg | jpeg|pdf",
        "danger"
      );
    }
  };

  const onSubmit = () => {
    if (file === "") {
      setAlert("لم يتم تحميل المرفقات", "danger");
    } else {
      addFileToReviewer(file, id);
      setAlert(`تم تحميل المرفقات بنجاح `, "success");
    }
  };

  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <Dropdown.Item className="btn btn-dark" onClick={handleShow}>
      موافق
      </Dropdown.Item>

      <Modal className="text-right" show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> تحميل المرفقات </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div>
              <h3>المرفقات

</h3>
<h4><ol>
<li>اثبات الهوية ( جواز السفر ، أو بطاقة تعريف )</li>
<li>آخر مؤهل علمي معتمد أو إفادة من الجامعة او المركز  البحثي تفيذ بالمؤهل والدرجة .</li>
</ol></h4>


            </div>
            <FormInput type="file" onChange={onChangefile} multiple />
            <p className="text-center">
              يمكن تحميل ملف في (pdf , jpg , jpeg) هدا الحقل
            </p>
            <Alert />
            {/* /////// */}
          </div>
          <Modal.Footer>
            <button className="btn btn-dark center" onClick={handleClose}>
              اغلاق
            </button>
            <button className="btn btn-primary center" onClick={onSubmit}>
              ارسال
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  setAlert,
  addFileToReviewer,
})(AddfiletoReviewer);
