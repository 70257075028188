import React, { Fragment, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { preview, Close, CVV } from "../research/Language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import "./bootstrap.css";

const Details = ({
  rolls,
  lang,
  research: {
    _id,
    CV,
    researchName,
    abstruct,
    theHub,
    author,
    email,
    phone,
    country,
    Organization,
    Qualification,
    Degree,
    author1,
    email1,
    author2,
    email2,
    createdAt,
    finalResualt,
    Notes,
    status,
    status2,
    Notes2,
  },
}) => {
  const [addModals, setShow] = useState(false);
  // eslint-disable-next-line
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <Fragment>
      <Dropdown.Item onClick={handleShow}>{preview[lang]}</Dropdown.Item>

      <Modal show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> {preview[lang]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ lineHeight: "2.4rem", fontSize: "1.3rem" }}>
            <h5 className="center-align">{researchName}</h5>
            <ul className="right-align">
              <li>
                {" "}
                <span className="flow-text"> الرقم المعرف :</span>
                {_id}
              </li>

              <li>
                {" "}
                <span className="flow-text">المحور /Axis :</span>
                {theHub}
              </li>
              {(rolls === 1 || rolls === 2) && (
                <>
                  <li>
                    <span className="flow-text">
                      اسم الباحث / Name of Author :
                    </span>{" "}
                    {author}
                  </li>
                  <li>
                    <span className="flow-text">رقم الهاتف :</span> {phone}
                  </li>
                  <li>
                    <span className="flow-text">البريد الالكتروني :</span>{" "}
                    {email}
                  </li>
                  <li>
             
                <span className="flow-text">الدولة / Country :</span> {country}
              </li>
              <li>
                <span className="flow-text">
                  الجهة التابع لها / Organization :
                </span>{" "}
                {Organization}
              </li>
                </>
              )}

             
              <li>
                {" "}
                <span className="flow-text">
                  المؤهل العلمي / Qualification :
                </span>
                {Qualification}
              </li>
              <li>
                {" "}
                <span className="flow-text">الدرجة العلمية / Degree :</span>
                {Degree}
              </li>
              {(rolls === 1 || rolls === 2) && (
                <>
                  {author1 && (
                    <Fragment>
                      <li>
                        <span className="flow-text">
                          اسم الباحث التاني / Name of Author 2:
                        </span>{" "}
                        {author1}
                      </li>
                      <li>
                        {" "}
                        <span className="flow-text">
                          البريد الالكتروني :
                        </span>{" "}
                        {email1}
                      </li>
                    </Fragment>
                  )}
                  {author2 && (
                    <Fragment>
                      <li>
                        {" "}
                        <span className="flow-text">
                          اسم الباحث الثالث / Name of Author 3 :
                        </span>
                        {author2}
                      </li>
                      <li>
                        {" "}
                        <span className="flow-text">البريد الالكتروني :</span>
                        {email2}
                      </li>
                    </Fragment>
                  )}
                </>
              )}
              <li>
                {" "}
                <span className="flow-text">الملخص / Abstruct :</span>
              </li>
              <li
                dangerouslySetInnerHTML={{ __html: abstruct }}
                className="text-justify p-2"
              ></li>
              {(rolls === 1 || rolls === 2) && (
                <>
                  {Notes && Notes !== "" && (
                    <div className="card">
                      <h3
                        className={
                           status < 50 
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {" "}
                        نتيجة المقيم الاول علي الورقة :{" "}
                        {status }
                      </h3>
                      {Notes.map((note) => (
                        <>
                          <li>
                            {note.quistion} - {note.degree}
                          </li>
                        </>
                      ))}
                    </div>
                  )}

                  {Notes2 && Notes2 !== "" && (
                    <div className="card">
                      <h3
                        className={
                          status2 < 50
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        نتيجة المقيم الثاني علي الورقة :{" "}
                        {status2 }
                      </h3>
                      {Notes2.map((note) => (
                        <>
                          <li>
                            {note.quistion} - {note.degree}
                          </li>
                        </>
                      ))}
                    </div>
                  )}
                  {finalResualt && finalResualt > 0 && (
                    <div className="card">
                      <h3
                        className={
                          finalResualt < 100
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        مجموع نتائج التقييم :{" "}
                        {finalResualt }
                      </h3>
                     
                    </div>
                  )}

                  
                </>
              )}
            </ul>
          </div>

          <Modal.Footer>
            <button className="btn btn-dark center" onClick={handleClose}>
              إغلاق
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Details;
