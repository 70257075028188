export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

export const GET_RESEARCHS = "GET_RESEARCHS";
export const GET_RESEARCH = "GET_RESEARCH";
export const RESEARCH_ERROR = "RESEARCH_ERROR";
export const ADD_RESEARCH = "ADD_RESEARCH";
export const UPDATE_RESEARCH = "UPDATE_RESEARCH";
export const CHANGE_LANG = "CHANGE_LANG";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const GET_REVIEWER = "GET_REVIEWER";
export const FILTER_RESEARCH = "FILTER_RESEARCH";
export const FILTERING_RESEARCH = "FILTERING_RESEARCH";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const USER_AGREE = "USER_AGREE";
export const USER_UPDATE = "USER_UPDATE";
export const USER_DELETE = "USER_DELETE";


