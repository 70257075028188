import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../Layout/Spinner";
import { getResearchs, getUsers } from "../../actions/research";
import ResearchItems from "./ResearchItems";
import { filtering, ChangeLang } from "../../actions/research";
import AgreementPage from "./AgreementPage";
import {
 
  NumPaperRecev,
  NumPaperWithNotes,
  NumPaperAccep,
  NumPaperRejc,
  Papers,
  titlePaper,
  nameAuthor,
  Axis,
  Country,
  statusAbs,
  participants,
  TypeParticipation,
  NumPaperDecoder,
} from "./Language";
import FiltersResearchs from "./filtersResearchs";

const Research = ({
  ChangeLang,
  getResearchs,
  getUsers,
  isAuthenticated,
  user: { _id, rolls, Agreement,name,files },
  researchs: { researchs, filtered, lang, loading },
}) => {
  useEffect(() => {
    getResearchs();
    getUsers();
    // eslint-disable-next-line
  }, [loading]);
  

  let AcceptedPapers = researchs.filter(
    (r) => r.status3 === "accepted" 
  );
  let numPaperWithNotes = researchs.filter(
    (r) => r.status3 === "acceptedwithnotes" 
  );
  let RejectedPapers = researchs.filter(
    (r) => r.status3 === "rejected" 
  );
  let papers = researchs.length;

  let paperDeco = researchs.filter(
    (r) => r.paperAfterEdit && r.paperAfterEdit !== ""
  );
  let i = 0;
  // ************************
  

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <section
        className="section section-posts grey lighten-4 "
        style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
      >
        <div className="row">
          <div className="col s12">
            <div className="mx-1">
              <a
                href="#!"
                className="btn btn-primary p-2"
                onClick={() => ChangeLang("ar")}
              >
                عربي
              </a>
              <a
                href="#!"
                className="btn btn-primary p-2"
                onClick={() => ChangeLang("en")}
              >
                English
              </a>
              <a
                href="#!"
                className="btn btn-primary p-2"
                onClick={() => ChangeLang("fr")}
              >
                france
              </a>
            </div>
            <div style={userStyle}>
              <div className="card  bg-primary center-align">
                <h4>{NumPaperRecev[lang]} </h4>
                <h4>{papers}</h4>
              </div>
              <div className="card  bg-primary center-align">
                <h4>{NumPaperDecoder[lang]} </h4>
                <h4>{paperDeco.length}</h4>
              </div>

              <div className="card  bg-dark center-align">
                <h4>{NumPaperAccep[lang]} </h4>
                <h4>{AcceptedPapers.length}</h4>
              </div>
              <div className="card  bg-dark center-align">
                <h4>{NumPaperWithNotes[lang]} </h4>
                <h4>{numPaperWithNotes.length}</h4>
              </div>

              
              <div className="card  bg-primary center-align">
                <h4>{NumPaperRejc[lang]} </h4>
                <h4>{RejectedPapers.length}</h4>
              </div>
            </div>
            <div className="card">
              <div className="card-content  ">
                {/* ************************ */}
               

                 <FiltersResearchs   />

                {isAuthenticated && rolls === 0 && Agreement !== true ? (
                  <AgreementPage id={_id} name={name} files = {files}  />
                ) : (
                  <>
                    <h1 className="text-center">{participants[lang]}</h1>
                    <table className="striped center-align">
                      <thead>
                        <tr>
                          <th className="center-align">ر.م</th>
                          <th className="center-align">{Papers[lang]}</th>
                          <th className="center-align"> {titlePaper[lang]}</th>

                          <th className="center-align"> {nameAuthor[lang]}</th>
                          <th className="center-align">
                            {TypeParticipation[lang]}
                          </th>
                          <th className="center-align">{Axis[lang]}</th>
                          <th className="center-align">{Country[lang]}</th>
                          <th className="center-align "> {statusAbs[lang]}</th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filtered !== null && filtered.length !== 0
                          ? filtered.map((research) => (
                              <Fragment key={research._id}>
                                {isAuthenticated &&
                                (rolls === 2 || rolls === 1) ? (
                                  <ResearchItems
                                    research={research}
                                    lang={lang}
                                    i={(i += 1)}
                                    rolls={rolls}
                                    reviewer_id={_id}
                                  />
                                ) : (
                                  rolls === 0 &&
                                  research.Reviewer &&
                                  research.Reviewer.id === _id && (
                                    <ResearchItems
                                      research={research}
                                      key={research._id}
                                      lang={lang}
                                      i={(i += 1)}
                                      rolls={rolls}
                                      reviewer_id={_id}
                                    />
                                  )
                                )}
                              </Fragment>
                            ))
                          : researchs.map((research) => (
                              <Fragment key={research._id}>
                                {isAuthenticated &&
                                (rolls === 2 || rolls === 1) ? (
                                  <ResearchItems
                                    research={research}
                                    lang={lang}
                                    i={(i += 1)}
                                    rolls={rolls}
                                    reviewer_id={_id}
                                  />
                                ) : (
                                  rolls === 0 &&
                                  ((research.Reviewer &&
                                    research.Reviewer.id === _id) ||
                                    (research.Reviewer2 &&
                                      research.Reviewer2.id === _id)) && (
                                    <ResearchItems
                                      research={research}
                                      key={research._id}
                                      lang={lang}
                                      i={(i += 1)}
                                      rolls={rolls}
                                      reviewer_id={_id}
                                    />
                                  )
                                )}
                              </Fragment>
                            ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
const userStyle = {
  direction: "rtl",
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridGap: "0.2rem",
};

Research.propTypes = {
  getResearchs: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  researchs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  researchs: state.researchs,
  isAuthenticated: state.auth.isAuthenticated,
  user : state.auth.user
});

export default connect(mapStateToProps, {
  getResearchs,
  getUsers,
  ChangeLang,
})(Research);
