import React, { Fragment, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import "./Exams.style.scss";
import { connect } from "react-redux";
import { changeStatus } from "../../actions/research";
import { setAlert } from "../../actions/alert";
import Alert from "../Layout/Alert";

const DoEvalution = ({ changeStatus, research, lang, user, setAlert }) => {
  // for modals
  const [addModals, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setResearch({
      status: "",
      Notes: "",
      status2: "",
      Notes2: "",
      research_id: research._id,
    });
    setTotalDegree("");
    setTotal(0);
    setShow(false);
  };

  const exam = {
    Title: "تقرير خبرة",
    notes: `
    <h4 class="lead ">
    <h3>ملاحظة 01 : يتم وضع علامة ما بين 0 و 10 نقاط لكل عبارة.</h3>
    <h3>ملاحظة 02 : يتم منح التقييم وفق الشروط التاليت:</h3>
    <ol class="pr-2">
    <li>تقييم ضعيف يتم منح علامة تتراوح بين 0 و 3نقاط؛</li>
    <li>تقييم متوسط يتم منح علامة تتراوح بين 4 و 6نقاط؛</li>
    <li>تقييم جيد يتم منح علامة تتراوح بين 7 و 10 نقاط.</li>
    </ol>
    </h4>
    `,
    Questions: [
      {
        quistion: "الالتزام بالمنهجية العلمية للبحث",
        answers: ["1", 2, 3, 4, 5, 6, 7, 8, 9, 10],
        degree: 0,
      },
      {
        quistion: "التحكم في لغة البحث",
        answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        degree: 0,
      },
      {
        quistion: "ملائمة المراجع والمصادر مع الموضوع",
        answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        degree: 0,
      },
      {
        quistion: "وضوح الجداول والأشكال",
        answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        degree: 0,
      },
      {
        quistion: "توافق المداخلة مع محتوي محاورالملتقى",
        answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        degree: 0,
      },
      {
        quistion: "توافق العنوان مع مظمون المداخلة",
        answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        degree: 0,
      },
      {
        quistion: "التحكم في طرح إشكالية الموضوع",
        answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        degree: 0,
      },
      {
        quistion: "وضوح أهداف وأهمية البحث",
        answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        degree: 0,
      },
      {
        quistion: "مدي اختبار فرضيات البحث",
        answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        degree: 0,
      },
      {
        quistion: " القيمة العلمية المضافة لنتائج البحث",
        answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        degree: 0,
      },
    ],
  };

  const [Result, setResult] = useState(exam.Questions);
  const [totalDegree, setTotalDegree] = useState("");
  const [Total, setTotal] = useState(0);
  const [Research, setResearch] = useState({
    status: "",
    Notes: "",
    status2: "",
    Notes2: "",
    research_id: research._id,
  });

  const handleChange = (qidx) => (e) => {
    setResult(
      Result.map((R, sidx) =>
        qidx !== sidx ? R : { ...R, degree: parseInt(e.target.value) }
      )
    );
    let total = 0;
    Result.filter((r) => (total += parseInt(r.degree)));
    setTotal(total);
  };

  const onSubmit = () => {
     
    setTotalDegree(Total > 50 ? "accepted" : "rejected");
    if (research.Reviewer && research.Reviewer.id === user._id) {
      setResearch({
        ...Research,
        status: Total,
        Notes: Result,
      });
    } else {
      setResearch({
        ...Research,
        status2: Total,
        Notes2: Result,
      });
    }
    console.log(totalDegree);
  };
  const onSure = () => {
    changeStatus(Research);
    handleClose();
  };

  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <Dropdown.Item onClick={handleShow}>إبداء نتائج التقييم</Dropdown.Item>

      <Modal className="text-right" show={addModals} onHide={handleClose}>
        <Modal.Header className="bg-primary ">
          <Modal.Title className="text-center">
            {" "}
            <h2>{exam.Title}</h2>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Fragment>
            {totalDegree.length > 1 ? (
              <>
                {Total}
              </>
            ) : (
              <div className="my-3">
                <div className="card-container">
                  <div className="card text-center">
                    <h2>الملاحظات</h2>
                    <div
                      className="text-right container line-h"
                      dangerouslySetInnerHTML={{ __html: exam.notes }}
                    ></div>
                  </div>

                  <Fragment>
                    {/* /////// الامتحان  */}
                    <div className="card bg-primary  text-center">
                      <h1>القسم الأول: الجانب الشكلي</h1>
                    </div>
                    {exam.Questions &&
                      exam.Questions.slice(0, 4).map((ex, qidx) => (
                        <div className="card">
                          <div>
                            <h2>{ex.quistion}</h2>
                            <ol className="p-1 d-flex ">
                              {ex.answers &&
                                ex.answers !== [] &&
                                ex.answers.map((a) => (
                                  <div className="grid-2 text-right d-flex-item ">
                                    <label>
                                      <input
                                        onInput={handleChange(qidx)}
                                        value={a}
                                        name={qidx}
                                        type="radio"
                                      />{" "}
                                      <span>{a}</span>
                                    </label>
                                  </div>
                                ))}
                               
                            </ol>
                          </div>
                        </div>
                      ))}

                    <div className="card bg-primary  text-center">
                      <h1>القسم الثاني: من حيث المضمون</h1>
                    </div>
                    {exam.Questions &&
                      exam.Questions.slice(4, 11).map((ex, qidx) => (
                        <div className="card">
                          <div>
                            <h2>{ex.quistion}</h2>
                            <ol className="p-1 d-flex ">
                              {ex.answers &&
                                ex.answers !== [] &&
                                ex.answers.map((a) => (
                                  <div className="grid-2 text-right d-flex-item ">
                                    <label>
                                      <input
                                        onInput={handleChange(qidx + 4)}
                                        value={a}
                                        name={qidx + 4}
                                        type="radio"
                                      />{" "}
                                      <span>{a}</span>
                                    </label>
                                  </div>
                                ))}
                            </ol>
                          </div>
                        </div>
                      ))}
                      <div className="card">
                          <div>
                            <h2>ارجو التاكيد لحساب النتائج</h2>
                            <ol className="p-1 d-flex ">
                              
                                  <div className="grid-2 text-right d-flex-item ">
                                    <label>
                                      <input
                                        onInput={handleChange(11)}
                                        value={0}
                                        name={11}
                                        type="checkbox"
                                      />{" "}
                                      <span>تأكيد</span>
                                    </label>
                                  </div>
                             
                            </ol>
                          </div>
                        </div>
                  </Fragment>
                  {/* ///// نهاية الامتحان */}
                </div>
              </div>
            )}
          </Fragment>
          <Modal.Footer>
            <button className="btn btn-dark center" onClick={handleClose}>
              الغاء
            </button>
            {totalDegree.length < 1 ? (
              <button className="btn btn-primary" onClick={onSubmit}>
                إرسال التقييم
              </button>
            ) : (
              <button className="btn btn-primary" onClick={onSure}>
                تأكيد التقييم
              </button>
            )}
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  lang: state.researchs.lang,
  user: state.auth.user,
});
export default connect(mapStateToProps, { changeStatus, setAlert })(
  DoEvalution
);
