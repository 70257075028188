import React from "react";
import { deleteAccount } from "../../actions/auth";
import { connect } from "react-redux";
import AddfiletoReviewer from "../modals/AddfiletoReviewer";

const AgreementPage = ({ id, deleteAccount,name ,files}) => {
  return (
    <div>
     
      
      {files && files.length !==0 ? 
      <h3>
      يتم مراجعة المستنذات المرسلة و سنوافيكم بالنتائج علي بريدك الالكتروني
    </h3> :
     <>
     <h2>تعهد والتزام</h2>
     <h3>
أتعهد وألتزم انا  {name} بصفتي عضو  اللجنة العلمية للمؤتمر المغاربي الدولي لمستجدات التنمية المستدامة – تونس -  المنعقد في أيام 5-6-7-8-9 فيفري 2021 كعضو مراجع فقط في اللجنة العلمية للمؤتمر ، بأن أحترم الوقت المحدد من طرف رئيس اللجنة العلمية للمؤتمر في عملية التحكيم وإرجاع تقرير الخبرة في أجل أقصاه 15 يوم من تاريخ إرسال المداخلات لي  لغرض التحكيم.
<li>ملاحظة 01: يستفيد عضو اللجنة العلمية بصفة مراجع من شهادة عضوية في اللجنة العلمية للمؤتمر الدولي .</li>
<li>ملاحظة 02: لا تتكفل الجهة المنظمة بمصاريف الاقامة والنقل في حالة الحضور إلى فعاليات المؤتمر.</li>
<li>ملاحظة 03: في حالة مشاركة العضو بمداخلة في أحد محاور المؤتمر تخضع المداخلة لعملية التحكيم من طرف عضو مراجع آخر  بصفة شفافة ويتم قبولها أو رفضها.</li>
</h3>
     <AddfiletoReviewer id={id}/>
     <button className="btn btn-danger" onClick={() => deleteAccount(id)}>
      غير موافق{" "}
     </button>
     </>
     
    
    }
      
      
    </div>
  );
};
export default connect(null, {  deleteAccount })(AgreementPage);
