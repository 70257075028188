import React, { Fragment, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { addPaper } from "../../actions/research";
import Alert from "../Layout/Alert";
import FormInput from "../form-input/form-input.component";

const AddPaperAfetrEdit = ({ lang, setAlert, addPaper, id }) => {
  // for modals
  const [addModals, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  // for regester

  const [paper, setPaper] = useState("");
  const [filename, setFilename] = useState(
    "  تحميل ملف الورقة البحثية / Upload the Paper   "
  );

  const onChangefile = (e) => {
    if (
      e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      e.target.files[0].type === "application/msword"
    ) {
      setPaper(e.target.files[0]);
      setFilename(e.target.files[0].filename);
    } else {
      setAlert(
        "الملف غير مدعوم ارجو التأكد من ان يكون الملف بصيغة pdf او doc",
        "danger"
      );
    }
  };

  const onSubmit = () => {
    if (filename === " CV / تحميل ملف السيرة الذاتية ") {
      setAlert("لم يتم تحميل الورقة", "danger");
    } else {
      addPaper(paper, id);
      setAlert(`تم تحميل الورقة البحثية بعد تشفير `, "success");
    }
  };

  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <Dropdown.Item onClick={handleShow}>
        تحميل الورقة بعد التشفير
      </Dropdown.Item>

      <Modal className="text-right" show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> التسجيل في المؤتمر </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <FormInput type="file" onChange={onChangefile} />
            <p className="text-center">
              يمكن تحميل ملف في (pdf , doc) هدا الحقل
            </p>
            <Alert />
            {/* /////// */}
          </div>
          <Modal.Footer>
            <button className="btn btn-dark center" onClick={handleClose}>
              اغلاق
            </button>
            <button className="btn btn-primary center" onClick={onSubmit}>
              ارسال
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default connect(null, {
  setAlert,
  addPaper,
})(AddPaperAfetrEdit);
