import React, { Fragment, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";

import { connect } from "react-redux";

import { setAlert } from "../../actions/alert";
import { addReviewer } from "../../actions/research";
import Alert from "../Layout/Alert";
import "./bootstrap.css";

const AddReview = ({
  addReviewer,
  setAlert,
  research: { _id, researchName,theHub,Reviewer,Reviewer2 },
  reviewers,
}) => {
  // for modals
  const [addModals, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [ReviewerVar, setReviewer] = useState({
    id: "",
    name: "",
    email: "",
    id2: "",
    name2: "",
    email2: "",
  });
  const { id, name, email, id2, name2, email2 } = ReviewerVar;

  let revs = reviewers.filter((reviewer) => reviewer.rolls === 0 && reviewer.theHub&& reviewer.theHub.includes(theHub))



  const onChange = async (e) => {
    let reviewer = await reviewers.find((r) => r._id === e.target.value);
    setReviewer({
      ...ReviewerVar,
      id: reviewer._id,
      name: reviewer.name,
      email: reviewer.email,
    });
  };
  const onChangeOther = async (e) => {
    let reviewer = await reviewers.find((r) => r._id === e.target.value);
    setReviewer({
      ...ReviewerVar,
      id2: reviewer._id,
      name2: reviewer.name,
      email2: reviewer.email,
    });
  };

  const onSubmit = () => {
    if (id === "" && id2 === "") {
      setAlert("لم يتم اضافة اي مراجع", "danger");
    } else {
      addReviewer(ReviewerVar, _id);
      setShow(false);
    }
  };

  return (
    <Fragment>
      {/*  eslint-disable-next-line */}
      <Dropdown.Item onClick={handleShow}>إضافة مراجع</Dropdown.Item>

      <Modal className="text-right" show={addModals} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> إضافة المراجعين </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert />
  <h3 className="my-3 text-center">{researchName} </h3>
 {Reviewer && Reviewer.name !== "" ? <p>المراجع الاول تم اختياره و هو : {Reviewer.name} </p>:
 <select onChange={(e) => onChange(e)}>
 <option value=""> اختر المراجع الاول</option>

 {revs.map((r) => (
   <option value={r._id}>{r.name} - {r.numberOfResearchs}</option>
 ))}
</select>
}
          
          <br />
          {Reviewer2 && Reviewer2.name !== "" ? <p>المراجع الثاني تم اختياره و هو : {Reviewer2.name} </p>:
 <select onChange={(e) => onChangeOther(e)}>
 <option value="">اختر المراجع الثاني</option>

 {revs.map((r) => (
   <option value={r._id}>{r.name} - {r.numberOfResearchs}</option>
 ))}
</select>
}
          

          <Modal.Footer>
            <button className="btn btn-dark center" onClick={handleClose}>
              اغلاق
            </button>
            <button className="btn btn-primary center" onClick={onSubmit}>
              ارسال
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  reviewers: state.researchs.reviewers,
});
export default connect(mapStateToProps, {
  setAlert,
  addReviewer,
})(AddReview);
