import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import{faDownload} from "@fortawesome/free-solid-svg-icons";
import {connect } from "react-redux"
import { deleteAccount, IMAgree } from "../../actions/research";

const ReviwersItem = ({reviewer ,i,IMAgree ,deleteAccount}) => {
    const {_id,name,theHub,files,email,numberOfResearchs,Agreement} = reviewer

    const onSubmit =()=>{
        console.log(_id)
        deleteAccount(_id)
    }
    return (
        <>
        <tr>
    <td className="center-align">{i}</td>
    <td className="center-align">
        
        { !Agreement && files &&files.map(file => <>
                          {file.toLocaleLowerCase().includes("pdf") ? <a href={file}><FontAwesomeIcon icon={faDownload}/>{" "}</a> : 
                          <img src={file}   width="10" />}
                          </>)}

        
                          </td>
                          <td className="center-align">{name}</td>
                          <td className="center-align">{email}</td>
                          <td className="center-align">{numberOfResearchs}</td>
                          <td className="center-align">{theHub}</td>
                          <td className="center-align">
                              <button className="btn btn-danger" onClick={onSubmit}>حذف</button>
                              {!Agreement &&  <button className="btn btn-dark" onClick={() => IMAgree(_id)}>
                                  موافق
                                </button> }
                             
                          
                          </td>
                          </tr>
        </>
    )
}

export default connect(null , {IMAgree ,deleteAccount})(ReviwersItem)
