import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import FormInput from "../form-input/form-input.component";

const Register = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    rolls: "",
    theHub: "",
  });

  const { name, email, password, password2, theHub, rolls } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("كلمات المرور غير متطابقة", "danger");
    } else {
      register({ name, email, password, theHub, rolls });
      setAlert({ html: "تم عملية انشاء الحساب بنجاح" }, { outDuration: 500 });
      setFormData({
        name: "",
        email: "",
        password: "",
        password2: "",
        rolls: "",
        theHub: "",
      });
    }
  };

  // if (isAuthenticated) {
  //   return <Redirect to='/dashboard' />;
  // }

  return (
    <Fragment>
      <div className="row text-center container">
        <div className="col s12">
          <div className="card">
            <div className="card-content center">
              <h1 className="large teal-text">اضافة مسؤول</h1>
              <p className="lead">
                <i className="fas fa-user" /> تكوين حساب للاحد أعضاء اللجنة
              </p>

              <form
                className="form"
                onSubmit={(e) => onSubmit(e)}
                style={{ direction: "rtl" }}
              >
                <div className="form-group">
                  <FormInput
                    type="text"
                    placeholder="اسم المسؤول"
                    name="name"
                    value={name}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group">
                  <FormInput
                    type="email"
                    placeholder="البريد الالكتروني"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                  />
                  <small className="form-text">
                    الرجاء التأكد من صحة البريد الالكتروني
                  </small>
                </div>
                <div className="form-group">
                  <FormInput
                    type="password"
                    placeholder="كلمة المرور "
                    name="password"
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="form-group">
                  <FormInput
                    type="password"
                    placeholder="تأكيد كلمة المرور"
                    name="password2"
                    value={password2}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div class="input-field ">
                  <select
                    type="number"
                    name="rolls"
                    value={rolls}
                    onChange={(e) => onChange(e)}
                  >
                    <option value="0" selected>
                      اختر صلاحيات المسؤول
                    </option>
                    <option value="2">مسؤول اول</option>
                    <option value="1">مسؤول تاني</option>
                    <option value="0">مسؤول ثالث</option>
                  </select>
                  <label>صلاحيات المسؤولين</label>
                </div>

                <div class="input-field ">
                  <select
                    name="theHub"
                    value={theHub}
                    onChange={(e) => onChange(e)}
                  >
                    <option value="" selected>
                      اختر المحور المسؤول عنه
                    </option>
                    <option value="المحور الأول: التدريب الإلكتروني">
                      المحور الأول: التدريب الإلكتروني
                    </option>
                    <option value="المحور الثاني: التحول الرقمي والتنمية المستدامة">
                      المحور الثاني: التحول الرقمي والتنمية المستدامة
                    </option>
                    <option value="المحور الثالث: دور التعليم الإلكتروني داخل المؤسسات التعليمية والمراكز البحثية.">
                      المحور الثالث: دور التعليم الإلكتروني داخل المؤسسات
                      التعليمية والمراكز البحثية.
                    </option>
                    <option value="المحور الرابع: نظام الإدارة الإلكترونية.">
                      المحور الرابع: نظام الإدارة الإلكترونية.
                    </option>
                    <option value=" المحور الخامس: الاقتصاد الرقمي في عصر المعرفة.">
                      المحور الخامس: الاقتصاد الرقمي في عصر المعرفة.
                    </option>
                    <option value="المحور السادس: تجارب ناجحة في تطبيق التحول الرقمي.">
                      المحور السادس: تجارب ناجحة في تطبيق التحول الرقمي.
                    </option>
                  </select>
                  <label>المحاور</label>
                </div>
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="تسجيل"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
