import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import Research from "../components/research/Researchs";

const ResearchPage = ({ user }) => {
  return (
    <Fragment>
      {/* <!-- Section: Users --> */}
      <section className="section section-users">
        {user && <Research user={user} />}
      </section>

      {/* <!-- Footer --> */}
      <footer className="section bg-dark center-align footer">
        <p> قسم الموقع الالكتروني &copy; 2020</p>
      </footer>
    </Fragment>
  );
};
const mapStatetoProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStatetoProps)(ResearchPage);
