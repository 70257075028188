import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";

import researchs from "./researchReducer";

export default combineReducers({
  auth,
  researchs,
  alert,
});
